import dribble from "../../assets/playground/dribble.png";
import random from "../../assets/playground/random.png";
import countdown from "../../assets/playground/countdown.png";
import awwwards from "../../assets/playground/awwwards.png";
import dbms from "../../assets/playground/dbms.png";
import lol1 from "../../assets/playground/lol1.png";
import lol2 from "../../assets/playground/lol2.png";
import lol3 from "../../assets/playground/lol3.jpg";

export const projectsData = [
  {
    id: 1,
    projectName: "UX Research and Test Early Concepts",
    hasLink: true,
    img: dribble,
  },
  {
    id: 2,
    projectName: "High-Fidelity Designs and Prototypes in Figma ",
    hasLink: true,
    img: random,
  },
  {
    id: 3,
    projectName: "UX Design Process : Empathize , Define and Ideate",
    hasLink: true,  
    img: countdown,
  },
  {
    id: 4,
    projectName: "Foundations Of UX Design",
    hasLink: true,
    img: awwwards,
  },
  
  {
    id: 6,
    projectName: "Web Design in Adobe xd",
    hasLink: true,
    img: lol1,
  },
  {
    id: 7,
    projectName: "Design in UX for Social Good",
    hasLink: true,
    img: lol2,
  },
  {
    id: 5,
    projectName: "NPTEL-DBMS | IIT Kharagpur",
    hasLink: true,
    img: dbms,
  },
  
];
