import React from "react";
import {
  AboutContent,
  AboutDetailsContainer,
  AboutImage,
  AboutImageContainer,
  Resume,
  ResumeLink,
  StyledAbout,
} from "../styles/About.styled";
import { SectionHeading, SectionTitle } from "../styles/SectionHeading";
import { StyledParagraph } from "../styles/Typography.styled";
import myImage from "../../assets/g3.jpg";
import downloadIcon from "../../assets/icons/download-icon.svg";
import resume from "../../assets/resume.pdf";

const About = () => {
  return (
    <StyledAbout id="about">
      <SectionHeading dark="true" mb="3rem">
        <SectionTitle dark="true" number="02">
          About Me
        </SectionTitle>
      </SectionHeading>
      <AboutContent>
        <AboutImageContainer>
          <AboutImage  src={myImage} />
        </AboutImageContainer>
        <AboutDetailsContainer>
          <StyledParagraph>
          Enthusiastic UX Designer and Frontend Developer with a robust educational background and hands-on experience in creating user-centric web solutions. Adept at employing design thinking methodologies to solve complex problems with a focus on usability and aesthetics. Proficient with modern frontend technologies and design tools, committed to crafting interfaces that blend functionality with visual appeal to enhance user experience.
          </StyledParagraph>
          <StyledParagraph>
            I love to design and create responsive websites or web apps from
            scratch. The technologies, tools and languages I am using to build
            my projects are HTML, CSS, JavaScript, ReactJs, TailwindCSS,
            Bootstrap, NodeJs, ExpressJs, MongoDB, MySql, Git and GitHub, Figma, Adobe XD, Photoshop, illustrator and VS Code.
          </StyledParagraph>
          <StyledParagraph>
            I have eagerness to learn  new technologies and I love to
            share my knowledge to my fellow developers and designers. And I am
            looking forward to talk with you!
          </StyledParagraph>
          <Resume>
            <ResumeLink
              href={resume}
             icon={downloadIcon}
              download="resume-gaurav-basantani"
       >
             Download Resume
             </ResumeLink>
          </Resume>
        </AboutDetailsContainer>
      </AboutContent>
    </StyledAbout>
  );
};

export default About;
