import {
  // project1Laptop,
  // project2Laptop,
  // project4Laptop,
  // Magazine,
  // Frame,
  // InstagramUI,
  // Nike,
  // NikeUI,
  // Avatar,
  Project1,
  Project2,
  Project3,
  Project4,
  Project5,
  Project6,
  Project7,
  Project8,
  // Project9,
  Project10,
  Project11,
  Project12,
} from "./projectImages";

export const projectData = [
  {
    id: 1,
    image: Project1,
    // transitionImage: project1Mobile,
    title: "E-Commerce",
    codeLink: "https://github.com/gauravbasantani/gaurav-ecommerce-react-js",
    liveLink: "https://bright-piroshki-378994.netlify.app/",
  },
  {
    id: 2,
    image: Project2,
    // transitionImage: project2Mobile,
    title: "LMS-Admin",
    codeLink: " https://github.com/gauravbasantani/edupro-lms-admin",
    liveLink: "https://cerulean-tulumba-7bafcd.netlify.app/ ",
  },
  
  {
    id: 3,
    image: Project12,
    // transitionImage: project4Mobile,
    title: "TO-DO List",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://starlit-mochi-1457de.netlify.app/",
  },

  {
    id: 3,
    image: Project3,
    // transitionImage: project4Mobile,
    title: "Avatar",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://assets.adobe.com/id/urn:aaid:sc:VA6C2:3a190519-f41f-4d0d-a092-5d26f27a03cb?view=published",
  },

  {
    id: 5,
    image: Project4,
    // transitionImage: project4Mobile,
    title: "Magzine - Travel Zine",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://i.imgur.com/DLUZZAw.mp4",
  },
  {
    id: 6,
    image: Project5,
    // transitionImage: project4Mobile,
    title: "Furniture Brochure",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://assets.adobe.com/id/urn:aaid:sc:US:8655dbb8-ce5c-447a-8f72-cdde78e20f78?view=published",
  },
  {
    id: 7,
    image: Project6,
    // transitionImage: project4Mobile,
    title: "Instagram Post",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://www.figma.com/proto/soLAvlTU2mNfZ2PXkWSIv3/Instagram-Post-US-Gun?page-id=0%3A1&node-id=4-1757&starting-point-node-id=4%3A1757&mode=design&t=r2kYbfhYEUghpZBr-1",
  },
  {
    id: 8,
    image: Project10,
    // transitionImage: project4Mobile,
    title: "Super Quiz App",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://www.figma.com/proto/xPp8QJvXRPNeSSmV99tWHY/Quiz-App?node-id=73-1846&starting-point-node-id=73%3A1846&scaling=scale-down&mode=design&t=Kszoi8aKqlP3PCdx-1",
  },
  {
    id: 9,
    image: Project8,
    // transitionImage: project4Mobile,
    title: "Nike Air Max 90",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://www.figma.com/proto/c4x05Fhx64ooTd9tb4yvHh/Nike-Shoe?node-id=103%3A2&scaling=scale-down&page-id=0%3A1",
  },
  {
    id: 10,
    image: Project7,
    // transitionImage: project4Mobile,
    title: "Arizona Water Chatbot",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://www.figma.com/proto/1x1cjuzW7LITnJZIfBXdiN/Waterchatbot-redesign?type=design&node-id=540-701&t=wwVUCmTAHpXJJPyv-1&scaling=scale-down&page-id=0%3A1&mode=design",
  },
  {
    id: 11,
    image: Project11,
    // transitionImage: project4Mobile,
    title: "Gaurav's Mexica Restaurant",
    // codeLink: "https://github.com/monciego/coderig-landing-page",
    liveLink: "https://youtu.be/z6AOGN5oRsI?si=EVQBQ3aqbbm2j4cY",
  },

];
